import { makeStyles } from "../../common/Theme/Theme";
import values from "../../common/Theme/themes/breakpoints/values";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, values: values },
  } = theme;
  return {
    main_container: {
      display: "flex",
      flexFlow: "column wrap",
      alignItems: "center",
      background: "#F7F7F7",
      [theme.breakpoints.down(960)]: {
        width: "100vw",
      },
    },
    display_blocks_container: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      [theme.breakpoints.down(960)]: {
        flexWrap: "wrap",
      },
    },
    display_blocks_container_license: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}%`,
      display: "flex",
      justifyContent: "center",
      width: "100%",
      [theme.breakpoints.down(960)]: {
        flexWrap: "wrap",
      },
    },
    display_blocks_section: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down(values.md)]: {
        paddingInline: theme.spacing(3),
      },
    },
    left_block: {
      width: "70%",
      [theme.breakpoints.down(960)]: {
        width: "100%",
      },
    },
    right_block: {
      width: 320,
      [theme.breakpoints.down(960)]: {
        width: "100%",
      },
    },
    top_leaderboard: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: theme.spacing(4),
    },
    top_leaderboard_mobile: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderBottom: "1px solid #C4C4C4",
    },
    secondary_leaderboard: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: theme.spacing(3),
    },
    secondary_leaderboard_mobile: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .ads_container": {
        marginBottom: 0,
        "& #leaderboard_mobile_1": {
          marginTop: 0,
        },
      },
    },
  };
});

export { useStyles };
