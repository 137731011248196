import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useSection,
  withSectionContextProvider,
} from "../../context/SectionContext";
import { useStyles } from "./Section.style";
import Ad from "../../components/Ad/Ad";
import RightBlocks from "../../components/RightBlocks/RightBlocks";
import LeftBlocks from "../../components/Section/LeftBlocks/LeftBlocks";
import { screenWidthIsHigherThan } from "../../hooks/useMediaQuery";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import withConfig from "../../utils/withConfig";
import { buildAdTargets } from "../../utils/googleTagManagerHelper";
import Layouts from "../../components/TopLocalNews/Layouts/Layouts";
import { NYVT_MEDIA_SLUG } from "../../utils/constants/licenseConstants";
const LEADERBOARD_AD_SIZE = [728, 90];
const LEADERBOARD_MOBILE_AD_SIZE = [320, 100];

const Section = ({ town, section, sponsor_info }) => {
  const classes = useStyles();
  const {
    localNews,
    loading,
    setSection,
    setTown,
    videoAd,
    hasLockedBullseyeAd,
  } = useSection();
  const [secondaryStories, setSecondaryStories] = useState([]);

  useEffect(() => {
    if (section) {
      setSection(section);
    }
    if (town) {
      setTown(town);
    }
  }, [town, section]);

  useEffect(() => {
    if (secondaryStories?.length === 0) {
      setSecondaryStories(localNews?.secondaryStories);
    }
  }, [localNews]);

  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={withConfig("RECAPTCHA_KEY")}
      scriptProps={{ async: true, defer: true, appendTo: "body" }}
    >
      <div className={classes.main_container}>
        {screenWidthIsHigherThan(767) ? (
          <div className={classes.top_leaderboard}>
            <Ad
              ad_unit={"tap_leaderboard_001"}
              size={LEADERBOARD_AD_SIZE}
              targets={buildAdTargets(town, section)}
            />
          </div>
        ) : (
          <div className={classes.top_leaderboard_mobile}>
            <Ad
              ad_unit={"leaderboard_mobile"}
              size={LEADERBOARD_MOBILE_AD_SIZE}
              targets={buildAdTargets(town, section)}
            />
          </div>
        )}

        <Layouts
          adTargets={buildAdTargets(town, section)}
          videoAd={videoAd}
          localNews={localNews}
          section={section}
          defaultTemplate
          townForSection={town}
          loading={loading}
          sponsorInfo={sponsor_info}
          hasLockedBullseyeAd={hasLockedBullseyeAd}
        />

        {screenWidthIsHigherThan(767) ? (
          <div className={classes.secondary_leaderboard}>
            <Ad
              ad_unit={"tap_leaderboard_002"}
              size={LEADERBOARD_AD_SIZE}
              targets={buildAdTargets(town, section)}
            />
          </div>
        ) : (
          <div className={classes.secondary_leaderboard_mobile}>
            <Ad
              ad_unit={"leaderboard_mobile_1"}
              size={LEADERBOARD_MOBILE_AD_SIZE}
              targets={buildAdTargets(town, section)}
            />
          </div>
        )}

        <section className={classes.display_blocks_section}>
          <div className={town?.slug != NYVT_MEDIA_SLUG ? classes.display_blocks_container : classes.display_blocks_container_license}>
            <div className={town?.slug != NYVT_MEDIA_SLUG ? classes.left_block : ''}>
              <LeftBlocks
                adTargets={buildAdTargets(town, section)}
                section={section}
                town={town ? town : null}
              />
            </div>
            {town?.slug != NYVT_MEDIA_SLUG && (
              <div className={classes.right_block}>
                <RightBlocks
                  adTargets={buildAdTargets(town, section)}
                  town={town ? town : null}
                  section={section}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </GoogleReCaptchaProvider>
  );
};

Section.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default withSectionContextProvider(Section);
